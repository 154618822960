import { make } from 'vuex-pathify'

const SOCKET_URL = 'wss://127.0.0.1:13579'

export const state = () => ({
  socket: null,
  isConnected: false,
  connectionError: false,
  shouldConnect: false
})

export const mutations = make.mutations(state)

export const actions = {
  connect ({ commit, dispatch }) {
    commit('SET_SHOULD_CONNECT', true)
    const socket = new WebSocket(SOCKET_URL)
    commit('SET_SOCKET', socket)
    socket.onopen = () => {
      commit('SET_IS_CONNECTED', true)
      commit('SET_CONNECTION_ERROR', false)
    }
    socket.onerror = (e) => {
      commit('SET_CONNECTION_ERROR', true)
    }
    socket.addEventListener('close', e => dispatch('socketClosed', e))
  },
  socketClosed ({ dispatch, commit, state }, e) {
    commit('SET_IS_CONNECTED', false)
    setTimeout(() => {
      if (state.shouldConnect) {
        dispatch('connect')
      }
    }, 5000)
  },
  disconnect ({ commit, state: { socket } }) {
    commit('SET_SHOULD_CONNECT', false)
    if (socket) {
      try {
        socket.disconnect()
      } catch (e) {

      }
    }
  },
  sendMessage ({ state: { socket } }, message) {
    return new Promise((resolve, reject) => {
      socket.send(JSON.stringify(message))
      socket.onmessage = ({ data }) => {
        delete socket.onmessage
        const { responseObject, code, message } = JSON.parse(data)
        if (code === '500') {
          return reject(message)
        }
        return resolve(responseObject)
      }
    })
  },
  getKeyInfo ({ dispatch }) {
    return dispatch('sendMessage', { module: 'kz.gov.pki.knca.commonUtils', method: 'getKeyInfo', args: ['PKCS12'] })
  }
}
