import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _be9dc5d0 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _08636161 = () => interopDefault(import('../pages/cabinet/index.vue' /* webpackChunkName: "pages/cabinet/index" */))
const _ad955a96 = () => interopDefault(import('../pages/client.vue' /* webpackChunkName: "pages/client" */))
const _fdbeb5e4 = () => interopDefault(import('../pages/clients.vue' /* webpackChunkName: "pages/clients" */))
const _c470ac24 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _143dedae = () => interopDefault(import('../pages/requests.vue' /* webpackChunkName: "pages/requests" */))
const _5152c090 = () => interopDefault(import('../pages/resetPassword.vue' /* webpackChunkName: "pages/resetPassword" */))
const _490e6182 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _c3de3e4e = () => interopDefault(import('../pages/simcards/index.vue' /* webpackChunkName: "pages/simcards/index" */))
const _746db05a = () => interopDefault(import('../pages/admin/agreements.vue' /* webpackChunkName: "pages/admin/agreements" */))
const _4b9f4416 = () => interopDefault(import('../pages/admin/agreements/index.vue' /* webpackChunkName: "pages/admin/agreements/index" */))
const _1f65f5d5 = () => interopDefault(import('../pages/admin/agreements/_status.vue' /* webpackChunkName: "pages/admin/agreements/_status" */))
const _22e67838 = () => interopDefault(import('../pages/admin/cto.vue' /* webpackChunkName: "pages/admin/cto" */))
const _6587cf67 = () => interopDefault(import('../pages/admin/cto/index.vue' /* webpackChunkName: "pages/admin/cto/index" */))
const _7ae49134 = () => interopDefault(import('../pages/admin/cto/inactive.vue' /* webpackChunkName: "pages/admin/cto/inactive" */))
const _6a58c02f = () => interopDefault(import('../pages/admin/login.vue' /* webpackChunkName: "pages/admin/login" */))
const _4104c2c8 = () => interopDefault(import('../pages/admin/reports/index.vue' /* webpackChunkName: "pages/admin/reports/index" */))
const _20d96eee = () => interopDefault(import('../pages/admin/requests.vue' /* webpackChunkName: "pages/admin/requests" */))
const _2a0efa1d = () => interopDefault(import('../pages/admin/sim.vue' /* webpackChunkName: "pages/admin/sim" */))
const _61669240 = () => interopDefault(import('../pages/admin/sim/index.vue' /* webpackChunkName: "pages/admin/sim/index" */))
const _6a3638e8 = () => interopDefault(import('../pages/admin/sim/active.vue' /* webpackChunkName: "pages/admin/sim/active" */))
const _9d57a0a8 = () => interopDefault(import('../pages/admin/sim/cto.vue' /* webpackChunkName: "pages/admin/sim/cto" */))
const _a06ad7e0 = () => interopDefault(import('../pages/admin/sim/rdt.vue' /* webpackChunkName: "pages/admin/sim/rdt" */))
const _05d175a8 = () => interopDefault(import('../pages/admin/sim/revoked.vue' /* webpackChunkName: "pages/admin/sim/revoked" */))
const _4b03c324 = () => interopDefault(import('../pages/admin/sim/stock.vue' /* webpackChunkName: "pages/admin/sim/stock" */))
const _33ca1164 = () => interopDefault(import('../pages/admin/users.vue' /* webpackChunkName: "pages/admin/users" */))
const _89ec49cc = () => interopDefault(import('../pages/cabinet/clients/index.vue' /* webpackChunkName: "pages/cabinet/clients/index" */))
const _15c3264a = () => interopDefault(import('../pages/cabinet/contractOffers.vue' /* webpackChunkName: "pages/cabinet/contractOffers" */))
const _0f31dc85 = () => interopDefault(import('../pages/cabinet/help/index.vue' /* webpackChunkName: "pages/cabinet/help/index" */))
const _363086b4 = () => interopDefault(import('../pages/cabinet/sim.vue' /* webpackChunkName: "pages/cabinet/sim" */))
const _5f7ca2ae = () => interopDefault(import('../pages/cabinet/sim/index.vue' /* webpackChunkName: "pages/cabinet/sim/index" */))
const _f03f8b82 = () => interopDefault(import('../pages/cabinet/sim/active.vue' /* webpackChunkName: "pages/cabinet/sim/active" */))
const _d6a52296 = () => interopDefault(import('../pages/cabinet/sim/revoked.vue' /* webpackChunkName: "pages/cabinet/sim/revoked" */))
const _3dbe23a4 = () => interopDefault(import('../pages/cabinet/sim/spare.vue' /* webpackChunkName: "pages/cabinet/sim/spare" */))
const _4dcd3b97 = () => interopDefault(import('../pages/cabinet/users.vue' /* webpackChunkName: "pages/cabinet/users" */))
const _7f3e4179 = () => interopDefault(import('../pages/cto/contracts/index.vue' /* webpackChunkName: "pages/cto/contracts/index" */))
const _2005cf57 = () => interopDefault(import('../pages/cto/reports/index.vue' /* webpackChunkName: "pages/cto/reports/index" */))
const _3e6eea27 = () => interopDefault(import('../pages/cto/simcards.vue' /* webpackChunkName: "pages/cto/simcards" */))
const _4408aa58 = () => interopDefault(import('../pages/help/requests.vue' /* webpackChunkName: "pages/help/requests" */))
const _666cb819 = () => interopDefault(import('../pages/simcards/acting.vue' /* webpackChunkName: "pages/simcards/acting" */))
const _374a72fe = () => interopDefault(import('../pages/simcards/writtenoff.vue' /* webpackChunkName: "pages/simcards/writtenoff" */))
const _3c23d4d8 = () => interopDefault(import('../pages/admin/reports/cto.vue' /* webpackChunkName: "pages/admin/reports/cto" */))
const _1d704bcd = () => interopDefault(import('../pages/admin/reports/sim.vue' /* webpackChunkName: "pages/admin/reports/sim" */))
const _795ddc61 = () => interopDefault(import('../pages/cabinet/help/requests.vue' /* webpackChunkName: "pages/cabinet/help/requests" */))
const _0e569542 = () => interopDefault(import('../pages/cto/contracts/canceled.vue' /* webpackChunkName: "pages/cto/contracts/canceled" */))
const _892aba40 = () => interopDefault(import('../pages/cto/contracts/current.vue' /* webpackChunkName: "pages/cto/contracts/current" */))
const _57e0844c = () => interopDefault(import('../pages/cto/contracts/formed.vue' /* webpackChunkName: "pages/cto/contracts/formed" */))
const _0439f685 = () => interopDefault(import('../pages/cto/contracts/signed.vue' /* webpackChunkName: "pages/cto/contracts/signed" */))
const _20baf512 = () => interopDefault(import('../pages/cto/reports/simcards.vue' /* webpackChunkName: "pages/cto/reports/simcards" */))
const _55a7dc02 = () => interopDefault(import('../pages/cabinet/clients/_id.vue' /* webpackChunkName: "pages/cabinet/clients/_id" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _be9dc5d0,
    name: "admin"
  }, {
    path: "/cabinet",
    component: _08636161,
    name: "cabinet"
  }, {
    path: "/client",
    component: _ad955a96,
    name: "client"
  }, {
    path: "/clients",
    component: _fdbeb5e4,
    name: "clients"
  }, {
    path: "/help",
    component: _c470ac24,
    name: "help"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/requests",
    component: _143dedae,
    name: "requests"
  }, {
    path: "/resetPassword",
    component: _5152c090,
    name: "resetPassword"
  }, {
    path: "/signup",
    component: _490e6182,
    name: "signup"
  }, {
    path: "/simcards",
    component: _c3de3e4e,
    name: "simcards"
  }, {
    path: "/admin/agreements",
    component: _746db05a,
    children: [{
      path: "",
      component: _4b9f4416,
      name: "admin-agreements"
    }, {
      path: ":status",
      component: _1f65f5d5,
      name: "admin-agreements-status"
    }]
  }, {
    path: "/admin/cto",
    component: _22e67838,
    children: [{
      path: "",
      component: _6587cf67,
      name: "admin-cto"
    }, {
      path: "inactive",
      component: _7ae49134,
      name: "admin-cto-inactive"
    }]
  }, {
    path: "/admin/login",
    component: _6a58c02f,
    name: "admin-login"
  }, {
    path: "/admin/reports",
    component: _4104c2c8,
    name: "admin-reports"
  }, {
    path: "/admin/requests",
    component: _20d96eee,
    name: "admin-requests"
  }, {
    path: "/admin/sim",
    component: _2a0efa1d,
    children: [{
      path: "",
      component: _61669240,
      name: "admin-sim"
    }, {
      path: "active",
      component: _6a3638e8,
      name: "admin-sim-active"
    }, {
      path: "cto",
      component: _9d57a0a8,
      name: "admin-sim-cto"
    }, {
      path: "rdt",
      component: _a06ad7e0,
      name: "admin-sim-rdt"
    }, {
      path: "revoked",
      component: _05d175a8,
      name: "admin-sim-revoked"
    }, {
      path: "stock",
      component: _4b03c324,
      name: "admin-sim-stock"
    }]
  }, {
    path: "/admin/users",
    component: _33ca1164,
    name: "admin-users"
  }, {
    path: "/cabinet/clients",
    component: _89ec49cc,
    name: "cabinet-clients"
  }, {
    path: "/cabinet/contractOffers",
    component: _15c3264a,
    name: "cabinet-contractOffers"
  }, {
    path: "/cabinet/help",
    component: _0f31dc85,
    name: "cabinet-help"
  }, {
    path: "/cabinet/sim",
    component: _363086b4,
    children: [{
      path: "",
      component: _5f7ca2ae,
      name: "cabinet-sim"
    }, {
      path: "active",
      component: _f03f8b82,
      name: "cabinet-sim-active"
    }, {
      path: "revoked",
      component: _d6a52296,
      name: "cabinet-sim-revoked"
    }, {
      path: "spare",
      component: _3dbe23a4,
      name: "cabinet-sim-spare"
    }]
  }, {
    path: "/cabinet/users",
    component: _4dcd3b97,
    name: "cabinet-users"
  }, {
    path: "/cto/contracts",
    component: _7f3e4179,
    name: "cto-contracts"
  }, {
    path: "/cto/reports",
    component: _2005cf57,
    name: "cto-reports"
  }, {
    path: "/cto/simcards",
    component: _3e6eea27,
    name: "cto-simcards"
  }, {
    path: "/help/requests",
    component: _4408aa58,
    name: "help-requests"
  }, {
    path: "/simcards/acting",
    component: _666cb819,
    name: "simcards-acting"
  }, {
    path: "/simcards/writtenoff",
    component: _374a72fe,
    name: "simcards-writtenoff"
  }, {
    path: "/admin/reports/cto",
    component: _3c23d4d8,
    name: "admin-reports-cto"
  }, {
    path: "/admin/reports/sim",
    component: _1d704bcd,
    name: "admin-reports-sim"
  }, {
    path: "/cabinet/help/requests",
    component: _795ddc61,
    name: "cabinet-help-requests"
  }, {
    path: "/cto/contracts/canceled",
    component: _0e569542,
    name: "cto-contracts-canceled"
  }, {
    path: "/cto/contracts/current",
    component: _892aba40,
    name: "cto-contracts-current"
  }, {
    path: "/cto/contracts/formed",
    component: _57e0844c,
    name: "cto-contracts-formed"
  }, {
    path: "/cto/contracts/signed",
    component: _0439f685,
    name: "cto-contracts-signed"
  }, {
    path: "/cto/reports/simcards",
    component: _20baf512,
    name: "cto-reports-simcards"
  }, {
    path: "/cabinet/clients/:id",
    component: _55a7dc02,
    name: "cabinet-clients-id"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
