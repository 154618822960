import Vue from 'vue'
export const state = () => ({
  socket: {
    isConnected: false,
    message: '',
    reconnectError: false
  },
  registrationData: null
})

export const actions = {
  async nuxtServerInit ({ dispatch }) {
    await Promise.all([
      dispatch('cashRegistersModels/load', { root: true }),
      dispatch('cities/load', { root: true })
    ])
  }
}

export const mutations = {
  // eslint-disable-next-line no-undef
  SOCKET_ONOPEN (state, event) {
    Vue.prototype.$socket = event.currentTarget
    state.socket.isConnected = true
  },
  // eslint-disable-next-line no-undef
  SOCKET_ONCLOSE (state, event) {
    state.socket.isConnected = false
  },
  // eslint-disable-next-line no-undef
  SOCKET_ONERROR (state, event) {
    window.console.error(state, event)
  },
  // eslint-disable-next-line no-undef
  SOCKET_ONMESSAGE (state, message) {
    state.socket.message = message
  },
  // eslint-disable-next-line no-undef
  SOCKET_RECONNECT (state, count) {
    window.console.info(state, count)
  },
  SOCKET_RECONNECT_ERROR (state) {
    state.socket.reconnectError = true
  },
  saveRegistrationData (state, data) {
    state.registrationData = data
  }
}
