<template>
  <component :is="tag">
    {{ roleName }}
  </component>
</template>

<script>

import roles from '@/admin-roles'

export default {
  name: 'AdminRoleName',
  props: {
    tag: {
      type: String,
      default: 'span'
    },
    role: {
      required: true,
      type: String
    }
  },
  computed: {
    roleName () {
      const role = roles.find(r => r.role === this.role)
      return role ? role.name : 'UNKNOWN_ROLE'
    }
  }
}
</script>

<style scoped>

</style>
