import { make } from 'vuex-pathify'

export const state = () => ({
  count: 0
})

export const mutations = make.mutations(state)

export const actions = {
  async updateCount ({ commit }) {
    const { count } = await this.$axios.$get('/api/cabinet/simCards/supplyRequests/count', {
      progress: false
    })
    commit('SET_COUNT', count)
  }
}
