import { make } from 'vuex-pathify'

export const state = () => ({
  items: []
})

export const mutations = make.mutations(state)

export const actions = {
  async load ({ commit }) {
    const items = await this.$axios.$get('/api/cashRegistersModels')
    commit('SET_ITEMS', items)
  }
}

export const getters = {
  getModelNameById: state => (id) => {
    const item = state.items.find(item => item.id === id)
    return item ? item.title : null
  }
}
