import { make } from 'vuex-pathify'

const state = () => ({
  permissions: []
})

export const mutations = make.mutations(state)

export const actions = {
  async load ({ commit }) {
    const permissions = await this.$axios.$get('/api/cabinet/permissions')
    commit('SET_PERMISSIONS', permissions)
  }
}

export const getters = {}
