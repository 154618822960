import { make } from 'vuex-pathify'

export const state = () => ({
  certificate: null,
  certificateInfo: null,
  operationError: null,
  agreement: null,
  step: 'first'
})

export const mutations = make.mutations(state)

export const getters = {
  contactPerson: ({ certificateInfo }) => certificateInfo ? [certificateInfo.lastName, certificateInfo.firstName, certificateInfo.middleName].join(' ') : ''
}

export const actions = {
  triggerOperationError (ctx, error) {
    ctx.commit('SET_OPERATION_ERROR', error.response.data.message)
  },
  clearOperationError ({ commit }) {
    commit('SET_OPERATION_ERROR', null)
  },
  async checkCertificate ({ dispatch, commit }, certificate) {
    try {
      const certificateInfo = await this.$axios.$post('/api/ctoRegistrationRequest/checkCertificate', { certificate })
      commit('SET_CERTIFICATE', certificate)
      commit('SET_CERTIFICATE_INFO', certificateInfo)
      commit('SET_AGREEMENT', certificateInfo.agreement)
      if (certificateInfo.agreement) {
        commit('SET_STEP', 'second')
      }
    } catch (e) {
      dispatch('triggerOperationError', e)
    }
  },
  async create ({ commit, state, dispatch }, formData) {
    const requestData = {
      address: formData.address,
      certificate: state.certificate,
      email: formData.email,
      cityRcoId: formData.cityRcoId,
      supportedCashRegisterModels: formData.cashRegisterModels.map(item => item.id),
      phone1: formData.firstPhone,
      phone2: formData.secondPhone,
      workingHours: formData.workingHours
    }

    const form = new FormData()
    form.append('file', formData.charter)
    requestData.charterFile = await this.$axios.$post('/api/storage/upload', form)

    try {
      const agreement = await this.$axios.$post('/api/ctoRegistrationRequest', requestData)
      commit('SET_AGREEMENT', agreement)
      commit('SET_STEP', 'second')
    } catch (e) {
      dispatch('triggerOperationError', e)
    }
  },
  async submit ({ dispatch, state }, data) {
    const form = {
      termsOfCooperationAcceptance: data.termsOfCooperationAcceptance,
      signature: data.signature,
      registrationCertificate: null,
      password: data.password,
      passwordRepeat: data.passwordRepeat
    }

    if (data.registrationCertificate) {
      const formData = new FormData()
      formData.append('file', data.registrationCertificate)
      form.registrationCertificate = await this.$axios.$post('/api/storage/upload', formData)
    }

    try {
      await this.$axios.$post('/api/ctoRegistrationRequest/submit', form)
      await dispatch('ctoUserAuthentication/authenticated')
      window.location.href = '/cabinet'
    } catch (e) {
      dispatch('triggerOperationError', e)
    }
  }
}
