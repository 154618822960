import { make } from 'vuex-pathify'

export const state = () => ({
  profile: null
})

export const mutations = make.mutations(state)

export const actions = {
  async loadProfile ({ commit }) {
    const { data: profile, status } = await this.$axios.get('/api/cabinet/profile', {
      validateStatus: status => status <= 500
    })
    if (status === 200) {
      commit('SET_PROFILE', profile)
    }
  },
  async logout () {
    await this.$axios.post('/api/cabinet/logout')
    this.app.$cookies.remove('authenticatedAs')
    await this.app.router.push({ name: 'login' })
  },
  async authenticateByDigitalSignature ({ dispatch }, signature) {
    await this.$axios.$post('/api/cabinet/security/authenticateByDigitalSignature', {
      signature
    })
    return dispatch('authenticated')
  },
  async authenticated ({ dispatch }) {
    this.app.$cookies.set('authenticatedAs', 'cto', {
      maxAge: 3600 * 24 * 365,
      path: '/',
      sameSite: 'lax'
    })
    await dispatch('loadProfile')
  },
  async login ({ dispatch }, credentials) {
    await this.$axios.post('/api/cabinet/login', credentials)
    await dispatch('authenticated')
  }
}

export const getters = {
  userName: ({ profile }) => [profile.firstName, profile.lastName].join(' ')
}
