<template>
  <nuxt />
</template>

<script>
export default {
  head () {
    return {
      meta: [
        { hid: 'og:image', name: 'og:image', content: `${process.env.NUXT_ENV_BASE_URL}/og_image.jpg` }
      ]
    }
  }
}
</script>
<style>

</style>
