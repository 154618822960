import { make } from 'vuex-pathify'

export const state = () => ({
  profile: null
})

export const mutations = make.mutations(state)

export const actions = {
  async login ({ dispatch }, credentials) {
    const { status } = await this.$axios.post('/api/admin/login', credentials, {
      validateStatus: status => status <= 400
    })

    if (status < 400) {
      this.app.$cookies.set('authenticatedAs', 'admin', {
        maxAge: 3600 * 24 * 365,
        path: '/',
        sameSite: 'lax'
      })
      await dispatch('loadProfile')
      await this.app.router.push({ name: 'admin' })
    }
  },
  async loadProfile ({ dispatch, commit }) {
    const { data: profile, status } = await this.$axios.get('/api/admin/profile', {
      validateStatus: status => status <= 500
    })
    if (status === 200) {
      commit('SET_PROFILE', profile)
    }
  },
  async logout () {
    await this.$axios.post('/api/admin/logout')
    this.app.$cookies.remove('authenticatedAs')
    await this.app.router.push({ name: 'admin-login' })
  }
}
