import { make } from 'vuex-pathify'

export const state = () => ({
  items: [],
  dictionary: {}
})

export const mutations = make.mutations(state)

export const actions = {
  async load ({ commit, state: { items } }) {
    if (items.length) {
      return
    }
    const operators = await this.$axios.$get('/api/simOperators')
    const dictionary = operators.reduce((prev, item) => {
      prev[item.id] = item.name
      return prev
    }, {})
    commit('SET_ITEMS', operators)
    commit('SET_DICTIONARY', dictionary)
  }
}
