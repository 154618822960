<template>
  <nuxt-link :to="{name: 'admin-sim'}" class="sidebar-link --sim-card" active-class="active">
    <span class="sidebar-link_text">Учёт SIM-карт</span><span v-if="count > 0" class="sidebar-link_count">{{ count }}</span>
  </nuxt-link>
</template>

<script>
import { get, call } from 'vuex-pathify'
export default {
  name: 'SimMenuLink',
  async fetch () {
    await this.updateCounter()
  },
  computed: {
    count: get('adminSimRequests/count')
  },
  mounted () {
    this.timer = setInterval(() => {
      this.updateCounter()
    }, 60 * 1000)
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    updateCounter: call('adminSimRequests/updateCount')
  }
}
</script>

<style scoped>

</style>
