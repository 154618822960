import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, length, min, email, confirmed, size } from 'vee-validate/dist/rules'

export default function () {
  extend('required', {
    ...required,
    message: 'Поле должно быть заполнено'
  })
  extend('length', {
    ...length,
    message: 'Поле должно состоять из {length} символов'
  })
  extend('min', min)
  extend('email', {
    ...email,
    message: 'Поле должно быть действительным электронным адресом'
  })

  extend('confirmed_password', {
    ...confirmed,
    message: 'Пароли должны совпадать'
  })

  extend('size', {
    ...size,
    message: ''
  })

  Vue.component('ValidationProvider', ValidationProvider)
  Vue.component('ValidationObserver', ValidationObserver)
}
