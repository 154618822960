<template>
  <div class="container-fluid">
    <div class="row">
      <admin-sidebar />
      <main role="main" class="main-content col">
        <nuxt />
      </main>
    </div>
  </div>
</template>

<script>
import AdminSidebar from '~/components/admin/AdminSidebar'
export default {
  components: { AdminSidebar }
}
</script>
