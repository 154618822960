import { make } from 'vuex-pathify'
import uniq from 'lodash/uniq'

export const state = () => ({
  company: null
})

export const mutations = make.mutations(state)

export const actions = {
  async loadCto ({ rootState, commit }) {
    const companyId = rootState.ctoUserAuthentication.profile.ctoId
    if (!companyId) { return }
    try {
      const company = await this.$axios.$get(`/api/cto/${companyId}/profile`)
      commit('SET_COMPANY', company)
    } catch (e) {
    }
  },

  async updateServicedModels ({ dispatch, state: { company } }, payload) {
    await this.$axios.$put(`/api/cto/${company.id}/servicedModels`, {
      servicedModels: uniq(payload)
    })
    await dispatch('loadCto')
  }
}

export const getters = {
  isFullRegistered: state => !!(state.company && state.company.coordinates),
  canHaveSubsidiary: state => state.company && state.company.companyType === 'parent' && state.company.form === 'organization',
  canCreateSubsidiary: (state, { canHaveSubsidiary }) => canHaveSubsidiary && state.company.permissions.includes('create_subsidiary_company')
}
