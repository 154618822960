<template>
  <component :is="tag" v-if="permissions.includes(attribute)">
    <slot />
  </component>
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'IsGranted',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    attribute: {
      required: true,
      type: String
    }
  },
  computed: {
    permissions: get('permissions/permissions')
  }
}
</script>

<style scoped>

</style>
