<template>
  <div class="container-fluid">
    <div class="row">
      <cabinet-sidebar />
      <main role="main" class="main-content col">
        <nuxt />
      </main>
    </div>
  </div>
</template>

<script>
import { call } from 'vuex-pathify'
import CabinetSidebar from '~/components/CabinetSidebar'
export default {
  components: { CabinetSidebar },
  async fetch () {
    await Promise.all([
      this.loadPermissions(),
      this.loadCtoCompany(),
      this.loadCashRegistersModels()
    ])
  },
  methods: {
    loadPermissions: call('permissions/load'),
    loadCtoCompany: call('cto/loadCto'),
    loadCashRegistersModels: call('cashRegistersModels/load')
  }
}
</script>

<style scoped>

</style>
